import React, { useEffect, useState } from "react";
import { Drawer, Layout, Menu } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  PieChartOutlined,
  MenuFoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import Home from "./Home";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Slices/AuthSlice";
import { Link, Switch, Route, useHistory } from "react-router-dom";
import Tutors from "./Tutors";
import { getTerms } from "../Redux/Slices/DashboardSlice";
const { Header, Content } = Layout;
export default function Main() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    dispatch(getTerms({ token: auth.token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function closeDrawer() {
    setOpen(false);
  }

  const logoutMenuItems = [
    { label: 'Logout', key: 'logout', icon: <LogoutOutlined /> }
  ]
  const baseMenu = [
    { key: "/", icon: <PieChartOutlined />, label: <Link to="/">Home</Link> },
    { key: "/tutors", icon: <UserOutlined />, label: <Link to="/tutors">Tutors</Link> },
  ]
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "white",
          position: "fixed",
          zIndex: 1,
          width: "100%",
          top: 0,
        }}
      >
        <div style={{ width: "100%" }}>
          <MenuOutlined onClick={() => setOpen(true)} />
        </div>
      </Header>
      <Content style={{ padding: "64px 16px" }}>
        <Drawer
          visible={open}
          placement="left"
          onClose={() => setOpen(false)}
          closeIcon={<MenuFoldOutlined />}
          bodyStyle={{ padding: 0 }}
          footerStyle={{ padding: 0, paddingBottom: 16 }}
          footer={
            <Menu mode="vertical" style={{ width: "100%" }} items={logoutMenuItems} onClick={() => {
              dispatch(logout());
            }} />
          }
        >
          <img
            src="/logo192.png"
            alt="Logo"
            style={{ widht: 36, height: 36, margin: 12 }}
          />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[history.location.pathname]}
            onClick={closeDrawer}
            items={baseMenu} />

        </Drawer>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/billing" exact>
            <Home />
          </Route>
          <Route path="/tutors">
            <Tutors />
          </Route>
        </Switch>
      </Content>
    </Layout>
  );
}
