import React, { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { copyStudent } from "../Redux/Slices/HomeSlice";

export default function CopyStudent({ student }) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const response = await dispatch(
      copyStudent({
        token: auth.token,
        body: { id: student.id },
      })
    );
    if (response.payload) {
      setVisible(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Tooltip title="Copy Student">
        <Button shape="circle" icon={<CopyOutlined />} onClick={showModal} />
      </Tooltip>
      <Modal
        title="Confirm Copy"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{`Are you sure you want to copy ${student.name}?`}</p>
      </Modal>
    </>
  );
}
