import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const getDashboard = createAsyncThunk(
  "dashboard/getDashboard",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).get(
      "/getDashboard",
      data
    );
    return response.data;
  }
);
export const getTerms = createAsyncThunk(
  "dashboard/getTerms",
  async (data,thunkAPI)=>{
    const response = await MakeRequest(null,thunkAPI).get("/getTerms");
    return response.data
  }
)
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    data: null,
    terms:null,
    termYears:[],
    selectedTerm:null
  },
  reducers: {
  
  },
  extraReducers: {
    [getDashboard.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.details
    },
    [getDashboard.pending]: (state, action) => {
      state.loading = true;
    },
    [getDashboard.rejected]: (state, action) => {
      state.loading = false;
    },
    [getTerms.fulfilled]:(state,action)=>{
      let terms = action.payload.details
      state.terms = terms
      let years = []
      terms.forEach(element => {

        years.push(element.year)
      });
      state.termYears = [...new Set(years)]
    }
  },
});
export default dashboardSlice.reducer;
