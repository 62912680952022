import { MailOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input, Drawer, Select, Form, Row, notification } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage, setIds } from "../Redux/Slices/HomeSlice";
const { Option } = Select;
const { TextArea } = Input;
export default function Email({ ids, title, disabled }) {
  const [show, setShow] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState("info@pointcooktutoring.com");
  const [loading, setLoading] = useState(false);
  const homeData = useSelector((state) => state.home);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  function onChange(value) {
    dispatch(setIds(value));
  }
  async function submit() {
    const key = `open${Date.now()}`;
    if (homeData.ids.length === 0) {
      notification.error({
        message: "Error Occured",
        description: "Atleast 1 recipient is required",
        key,
      });
      return;
    }
    if (subject.trim() === "") {
      notification.error({
        message: "Error Occured",
        description: "Subject is required",
        key,
      });
      return;
    }
    if (message.trim() === "") {
      notification.error({
        message: "Error Occured",
        description: "Message is required",
        key,
      });
      return;
    }
    let m = message.split("\n").join("<br>");
    setLoading(true);
    let data = {
      token: auth.token,
      body: {
        from: from,
        ids: homeData.ids,
        subject: subject,
        message: m,
      },
    };
    let response = await dispatch(sendMessage(data));
    setLoading(false);
    if (response.payload) {
      dispatch(setIds([]));
      setSubject("");
      setMessage("");
      setShow(false);
    }
  }
  return (
    <>
      <Button type="primary" disabled={disabled} onClick={() => setShow(true)}>
        <MailOutlined />
        {title}
      </Button>
      <Drawer
        destroyOnClose={true}
        title="Compose"
        onClose={() => setShow(false)}
        height={"80%"}
        visible={show}
        placement={"bottom"}
        footer={
          <Row justify="end">
            <Button
              type="primary"
              icon={<SendOutlined />}
              loading={loading}
              onClick={submit}
            >
              Send
            </Button>
          </Row>
        }
      >
        <Form layout="vertical">
          <Form.Item label="From">
            <Select
              onChange={(value) => setFrom(value)}
              value={from}
              style={{ width: "100%" }}
            >
              <Option value="info@selectschoolmelbourne.com.au">
                info@selectschoolmelbourne.com.au
              </Option>
              <Option value="info@pointcooktutoring.com">
                info@pointcooktutoring.com
              </Option>
              <Option value="accounts@pointcooktutoring.com">
                accounts@pointcooktutoring.com
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label="To">
            <Select
              mode="multiple"
              allowClear
              placeholder="To"
              onChange={onChange}
              style={{ width: "100%" }}
              optionFilterProp="label"
              // filterOption={(inputValue, option) =>
              //   option.label
              //     ? option.label
              //         .toString()
              //         .toLowerCase()
              //         .includes(inputValue.toLowerCase())
              //     : ""
              // }
              value={homeData.ids}
            >
              {homeData.data.map((x) => (
                <Option value={x.id} label={x.name} key={`${x.id}`}>
                  {x.name} ({x.email})
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Subject">
            <Input
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Message">
            <TextArea
              rows={10}
              placeholder="Message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
