import { PrinterOutlined } from '@ant-design/icons'
import { Skeleton ,Row,Button,Tooltip} from 'antd'
import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getInvoice } from '../Redux/Slices/InvoiceSlice'
import { url } from '../Utils/Config'

export default function ViewInvoice({ student }) {
    const dispatch = useDispatch()
    const {loading,data} = useSelector(state=>state.invoice)
    useEffect(() => {
        dispatch(getInvoice({
            id:student.id
        }))
        // eslint-disable-next-line
    }, [student])
    // const getData = async () => {
    //     setLoading(true)
    //     let data = new FormData()
    //     data.append('id', student.id)
    //     const response = await fetch(`${url}/viewInvoice`, {
    //         method: "POST", body: data, headers: {
    //             authorization: auth.token
    //         }
    //     })
    //     const responseJson = await response.json()
    //     setData(responseJson.details)
    //     setLoading(false)
    // }
    const print =async ()=>{
        let printDoc = new jsPDF('p','pt','a4',true)
        await printDoc.html(document.getElementById("invoice"),{
            html2canvas:{
                scale:0.7,
            },
            x:8,
            y:8
        })
        printDoc.save(`${student.name}-Invoice`)
    }
    return (
        loading ?
            <Skeleton />
            :
            <>
                <Row justify="end">
                    <Tooltip title="Print">
                        <Button icon={<PrinterOutlined/>} shape="circle" onClick={print}/>
                    </Tooltip>
                </Row>
                <div dangerouslySetInnerHTML={{ __html: data }} id="invoice"/>
            </>
    )
}
