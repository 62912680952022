import { PrinterOutlined } from '@ant-design/icons'
import { Skeleton ,Row,Button,Tooltip} from 'antd'
import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { url } from '../../Utils/Config'

export default function TutorInvoice({ tutor,term }) {
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState('')
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [tutor])
    const getData = async () => {
        setLoading(true)
        let data = new FormData()
        data.append('id', tutor.id)
        data.append('term_id',term)
        const response = await fetch(`${url}/getInvoice`, {
            method: "POST", 
            body: data,
            credentials:"include"
        })
        const responseJson = await response.json()
        setData(responseJson.details)
        setLoading(false)
    }
    const print =async ()=>{
        let printDoc = new jsPDF('p','pt')
        await printDoc.html(document.getElementById("invoice"),{
            html2canvas:{
                scale:0.8,
            },
            x:32,
            y:32
        })
        printDoc.save(`${tutor.name}-Invoice`)
    }
    return (
        loading ?
            <Skeleton />
            :
            <>
                <Row justify="end">
                    <Tooltip title="Print">
                        <Button icon={<PrinterOutlined/>} shape="circle" onClick={print}/>
                    </Tooltip>
                </Row>
                <div dangerouslySetInnerHTML={{ __html: data }} id="invoice"/>
            </>
    )
}
