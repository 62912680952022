import authReducer from "./Slices/AuthSlice";
import homeReducer from "./Slices/HomeSlice";
import crmReducer from "./Slices/CrmSlice";
import testRegistrationReducer from "./Slices/TestRegistrationSlice";
import tutorReducer from "./Slices/TutorSlice";
import dashboardReducer from "./Slices/DashboardSlice";
import invoiceReducer from "./Slices/InvoiceSlice";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist:['auth']
};
const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  crms: crmReducer,
  testRegistration: testRegistrationReducer,
  tutors: tutorReducer,
  dashboard:dashboardReducer,
  invoice: invoiceReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
export const persistor = persistStore(store);
export default store;
