import { LockOutlined, SendOutlined } from '@ant-design/icons'
import { Modal, Button, Input, Form, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendInvoices } from '../Redux/Slices/HomeSlice'



export default function SendInvoices({ ids, title, disabled, single }) {
    const [show, setShow] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const home = useSelector(state => state.home)
    const send = async () => {
        let isValid = await form.validateFields()
        if (isValid) {
            let sendingIds = [];
            if (ids) {
                sendingIds = ids
            } else {
                home.data.forEach(item => {
                    sendingIds.push(item.id)
                })
            }
            dispatch(sendInvoices({
                token: auth.token,
                body: {
                    ids: sendingIds,
                    password: form.getFieldValue("password")
                }
            }))
            form.resetFields()
            setShow(false)
        }
    }
    return (
        <>
            {single ?
                <Tooltip title="Send Invoice">
                    <Button shape="circle" icon={<SendOutlined onClick={() => setShow(true)} />} />
                </Tooltip> :
                <Button type="primary" disabled={disabled} onClick={() => setShow(true)}><SendOutlined />{title}</Button>
            }
            <Modal title='Do you Want to send invoices to everyone?' visible={show} onCancel={() => setShow(false)} onOk={send}>
                <Form form={form}>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter password' }]}
                    >
                        <Input.Password placeholder="Enter Password" prefix={<LockOutlined />} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
