import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const fetchTutors = createAsyncThunk(
  "tutors/fetch",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).get(
      "/fetchTutors"
    );
    return response.data;
  }
);
export const getWeeks = createAsyncThunk(
  "tutors/getWeeks",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).get(
      "/getWeeks"
    );
    return response.data;
  }
);
export const markTimesheetPaid = createAsyncThunk(
  "tutor/markTimesheetPaid",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).post(
      "/markTimesheetPaid",
      data.body
    );
    return response.data;
  }
);
export const fetchTimesheet = createAsyncThunk(
  "tutors/timesheet",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).post(
      "/fetchTimesheet",
      data.body
    );
    return response.data;
  }
);
export const addTutor = createAsyncThunk(
  "tutors/add",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).post(
      "/addTutor",
      data.body
    );
    return response.data;
  }
);
export const editTutor = createAsyncThunk(
  "tutors/edit",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).post(
      "/updateTutor",
      data.body
    );
    return response.data;
  }
);

export const deleteTutor = createAsyncThunk(
  "tutors/delete",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/deleteTutor",
      data.body
    );
    return response.data;
  }
);

const tutorSlice = createSlice({
  name: "tutors",
  initialState: {
    data: [],
    loading: false,
    tutorCount: 0,
    pendingAmount: 0,
    weeks: [],
  },
  extraReducers: {
    [fetchTutors.fulfilled]: (state, { payload }) => {
      state.loading = false;
      let data = payload.details;
      state.data = data;
      state.tutorCount = data.length;
      let amount = 0;
      data.forEach((element) => {
        amount += element.total_pending;
      });
      state.pendingAmount = amount;
    },
    [fetchTutors.pending]: (state) => {
      state.loading = true;
    },
    [fetchTutors.rejected]: (state) => {
      state.loading = false;
    },
    [addTutor.fulfilled]: (state, { payload }) => {
      let data = state.data;
      data.push(payload.details);
      state.data = data;
    },
    [editTutor.fulfilled]: (state, { payload }) => {
      let data = payload.details;
      let newData = [];
      state.data.forEach((x) => {
        if (x.id === data.id) {
          newData.push(data);
        } else {
          newData.push(x);
        }
      });
      state.data = newData;
    },
    [getWeeks.fulfilled]: (state, { payload }) => {
      state.weeks = payload.details;
    },
    [deleteTutor.fulfilled]: (state, { payload }) => {
      let newData = state.data.filter((x) => x.id !== payload.details);
      state.data = newData;
    },
  },
});

export default tutorSlice.reducer;
