import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Tooltip,
} from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editTutor } from "../../Redux/Slices/TutorSlice";
export default function EditTutor({ record }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    }
  }, [record, form]);
  const sendData = async () => {
    let isValid = await form.validateFields();
    if (isValid) {
      let data = form.getFieldsValue();
      for (let [key, value] of Object.entries(data)) {
        if (value === undefined) {
          delete data[key];
        }
      }
      data.id = record.id;
      dispatch(
        editTutor({
          token: auth.token,
          body: data,
        })
      );
      setVisible(false);
    }
  };

  return (
    <>
      <Tooltip title="Edit">
        <Button
          shape="circle"
          icon={<EditOutlined />}
          onClick={() => setVisible(true)}
        />
      </Tooltip>
      <Drawer
        destroyOnClose={true}
        title="Edit Tutor"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendData();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            ignore_end_date: false,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter student name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="abn" label="ABN">
                <Input placeholder="Please enter abn" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="email" label="Email">
                <Input placeholder="Please enter email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone_number" label="Phone Number" rules={[{ required: true, message: "Please enter phone number" }]}>
                <Input placeholder="Please enter phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="admin_rate"
                label="Admin Rate"
                rules={[{ required: true, message: "Please enter admin rate" }]}
              >
                <Input placeholder="Please enter admin rate" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tutoring_rate"
                label="Tutoring Rate"
                rules={[
                  { required: true, message: "Please enter tutoring rate" },
                ]}
              >
                <Input placeholder="Please enter tutoring rate" type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="address_line_1"
                label="Address Line 1"
                rules={[{ required: true, message: "Please enter Address Line 1" }]}
              >
                <Input placeholder="Please enter address line 1" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="address_line_2"
                label="Address Line 2"
              >
                <Input placeholder="Please enter address line 2" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
              >
                <Input placeholder="Please enter city" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="state"
                label="State Initial"
                rules={[{ required: true, message: "Please enter state" }]}
              >
                <Input placeholder="Please enter state" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
