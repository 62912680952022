import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Row, Col, Input } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTutor } from "../Redux/Slices/TutorSlice";
export default function AddTutor() {
  const [visible, setVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const sendData = async () => {
    let isValid = await form.validateFields();
    if (isValid) {
      setloading(true);
      let data = form.getFieldsValue();
      const response = await dispatch(
        addTutor({ token: auth.token, body: data })
      );
      if (response.payload) {
        setloading(false);
        setVisible(false);
        form.resetFields();
      } else {
        setloading(false);
      }
    }
  };
  return (
    <>
      <div style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Button
          shape="circle"
          type="primary"
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setVisible(true)}
        />
      </div>
      <Drawer
        destroyOnClose={true}
        title="Add Tutor"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => setVisible(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                sendData();
              }}
              type="primary"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            ignore_end_date: false,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input placeholder="Please enter student name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter email" }]}
              >
                <Input placeholder="Please enter email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="admin_rate"
                label="Admin Rate"
                rules={[{ required: true, message: "Please enter admin rate" }]}
              >
                <Input placeholder="Please enter admin rate" type={"number"} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tutoring_rate"
                label="Tutoring Rate"
                rules={[
                  { required: true, message: "Please enter tutoring rate" },
                ]}
              >
                <Input
                  placeholder="Please enter tutoring rate"
                  type={"number"}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="abn"
                label="ABN"
                rules={[{ required: true, message: "Please enter ABN" }]}
              >
                <Input placeholder="Please enter ABN"  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone_number"
                label="Phone Number"
                rules={[{ required: true, message: "Please enter phone number" }]}
              >
                <Input placeholder="Please enter phone number"  />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="address_line_1"
                label="Address Line 1"
                rules={[{ required: true, message: "Please enter Address Line 1" }]}
              >
                <Input placeholder="Please enter address line 1"  />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="address_line_2"
                label="Address Line 2"
              >
                <Input placeholder="Please enter address line 2"  />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
              >
                <Input placeholder="Please enter city"  />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="state"
                label="State Initial"
                rules={[{ required: true, message: "Please enter state" }]}
              >
                <Input placeholder="Please enter state"  />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
