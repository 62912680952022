import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const getInvoice = createAsyncThunk(
    'invoice/view',
    async (data, thunkAPI) => {
        const response = await MakeRequest(null, thunkAPI).post('/viewInvoice', data)
        return response.data
    }
)

const invoiceSlice = createSlice({
    name: 'invoice',
    initialState: {
        data:null,
        loading: false
    },
    extraReducers: {
        [getInvoice.fulfilled]: (state, action) => {
            state.loading = false
            state.data = action.payload.details
        },
        [getInvoice.pending]: (state) => {
            state.loading = true
        },
        [getInvoice.rejected]: (state) => {
            state.loading = false
        }
    }
})

export default invoiceSlice.reducer