/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select, Divider, Input, Typography, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function DropdownWithAddition({ options, value, onChange }) {
  const [items, setItems] = useState(options);
  const [name, setName] = useState("");
  const [selected, setSelected] = useState();
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  useEffect(() => {
    setSelected(value);
    if (value > 1) {
      setItems([...options, { value: value, text: `Partially Paid ${value}` }]);
    }
  }, [value]);
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, { value: name, text: `Partailly Paid ${name}` }]);
    setName("");
  };

  return (
    <Select
      style={{ width: 200 }}
      placeholder="Payment Status"
      value={selected}
      onChange={(v) => {
        onChange(v);
        setSelected(v);
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Space align="center" style={{ padding: "0 8px 4px" }}>
            <Input
              placeholder="Please enter amount"
              value={name}
              onChange={onNameChange}
              type="number"
            />
            <Typography.Link onClick={addItem} style={{ whiteSpace: "nowrap" }}>
              <PlusOutlined /> Add Amount
            </Typography.Link>
          </Space>
        </>
      )}
    >
      {items.map((item) => (
        <Option key={item.text} value={item.value}>
          {item.text}
        </Option>
      ))}
    </Select>
  );
}
