import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons'
import { Spin, Tooltip, Button, Drawer, notification, Row, Form, Input, Col, List } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getComments, addComment, archiveComment } from '../Redux/Slices/HomeSlice'
const { TextArea } = Input
export default function Comments({ id }) {
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const auth = useSelector(state => state.auth)
    const homeData = useSelector(state => state.home)
    const [comment, setComment] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        if(visible){
            getData()
        }
        // eslint-disable-next-line
    }, [visible])
    const getData = async () => {
        setLoading(true)
        let data = {
            token: auth.token,
            id: id
        }
        await dispatch(getComments(data))
        setLoading(false)
    }
    const addData = async () => {
        if (comment.trim() === "") {
            const key = `open${Date.now()}`;
            notification.error({
                message: 'Error Occured',
                description: "Please type the comment",
                key,
            });
            return
        }
        setLoading(true)
        let data = {
            token: auth.token,
            body: {
                student_id: id,
                comment: comment
            }
        }
        let response = await dispatch(addComment(data))
        if(response.payload){
            setComment("")
        }
        setLoading(false)
    }
    const archiveData = async (commentId)=>{
        console.log(commentId);
        if(window.confirm("Are you sure you want to archive this comment?")){
            setLoading(true)
            let data = {
                token:auth.token,
                body:{
                    id:commentId
                }
            }
            await dispatch(archiveComment(data))
            setLoading(false)
        }
    }
    return (
        <>
            <Tooltip title="History / Comments">
                <Button shape="circle" icon={<HistoryOutlined />} onClick={() => setVisible(true)} />
            </Tooltip>
            <Drawer
                destroyOnClose={true}
                title="Account History / Comments"
                width={window.innerWidth > 900 ? 720 : "100%"}
                onClose={() => setVisible(false)}
                visible={visible}
                footer={
                    <Row gutter={4}>
                        <Col flex="auto">
                            <Form.Item>
                                <TextArea rows={1} onChange={(e) => setComment(e.target.value)} value={comment}/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button loading={loading} onClick={addData} type="primary">Add Comment</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                }>
                <>
                <List
                    itemLayout="horizontal"
                    dataSource={homeData.comments}
                    renderItem={item=>(
                        <List.Item
                        actions={[
                        <Tooltip title="Archive">
                            <Button shape="circle" icon={<DeleteOutlined />} onClick={()=>{archiveData(item.id)}}/>
                        </Tooltip>
                    ]}>
                            <List.Item.Meta
                                title={item.comment}
                                description={`updated by ${item.updated_by} on ${moment(item.created_at).format("ddd , Do MMM YY, h:mm a")}`}
                            />
                        </List.Item>
                    )}
                />

                    {loading ?
                        <Row justify="center">
                            <Spin />
                        </Row>
                        :
                        null
                    }
                </>
            </Drawer>
        </>
    )
}
