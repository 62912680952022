import "./App.css";
import Main from "./Screens/Main";
import { useSelector } from "react-redux";
import Login from "./Screens/Login";
import { BrowserRouter } from "react-router-dom";

function App() {
  const auth = useSelector((state) => state.auth);

  return (
    <div className="App">
      {auth.authenticated ? (
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
