/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { markTimesheetPaid } from "../../Redux/Slices/TutorSlice";
import { url } from "../../Utils/Config";
import DropdownWithAddition from "../DropdownWithAddition";
const { Option } = Select;
let options = [
  { value: 0, text: "Not Paid" },
  { value: 1, text: "Paid in Full" },
];
export default function Report({term}) {
  const [selectedTutor, setSelectedTutor] = useState();
  const [selectedWeek, setSelectedWeek] = useState();
  const [loading, setLoading] = useState(false);
  const { data, weeks } = useSelector((state) => state.tutors);
  const { token } = useSelector((state) => state.auth);
  const [timesheet, setTimesheet] = useState([]);
  const [week, setWeek] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedTutor) {
      getData();
    }
  }, [selectedTutor,term]);
  useEffect(() => {
    if (selectedWeek) {
      getWeeklyData();
    }
  }, [selectedWeek,term]);
  const getData = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("id", selectedTutor);
    data.append("term_id",term)
    const response = await fetch(`${url}/fetchTimesheet`, {
      method: "POST",
      body: data,
      credentials:"include"
    });
    const responseJson = await response.json();
    let details = responseJson.details;
    let newData = [];
    let final_tutoring = 0;
    let final_admin = 0;
    let paid = 0;
    details.forEach((x) => {
      x.admin = x.admin_hours * x.admin_rate;
      x.tutoring = x.tutoring_hours * x.tutoring_rate;
      x.total = x.admin + x.tutoring;
      final_tutoring += x.tutoring;
      final_admin += x.admin;
      if (x.paid === 1) {
        paid += x.total;
      } else if (x.paid > 1) {
        paid += x.paid;
      }
      newData.push(x);
    });
    newData.push({
      week_name: "Total",
      admin: final_admin,
      tutoring: final_tutoring,
      total: final_admin + final_tutoring,
      status: paid,
    });
    setTimesheet(newData);
    setLoading(false);
  };
  const getWeeklyData = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("id", selectedWeek);
    data.append("term_id",term)
    const response = await fetch(`${url}/fetchWeek`, {
      method: "POST",
      body: data,
      credentials:"include"
    });
    const responseJson = await response.json();
    let details = responseJson.details;
    let newData = [];
    let final_tutoring = 0;
    let final_admin = 0;
    let paid = 0;
    details.forEach((x) => {
      x.admin = x.admin_hours * x.admin_rate;
      x.tutoring = x.tutoring_hours * x.tutoring_rate;
      x.total = x.admin + x.tutoring;
      final_tutoring += x.tutoring;
      final_admin += x.admin;
      if (x.paid === 1) {
        paid += x.total;
      } else if (x.paid > 1) {
        paid += x.paid;
      }
      newData.push(x);
    });
    newData.push({
      name: "Total",
      admin: final_admin,
      tutoring: final_tutoring,
      total: final_admin + final_tutoring,
      status: paid,
    });
    setWeek(newData);
    setLoading(false);
  };
  const columns = [
    {
      title: "Week",
      dataIndex: "week_name",
      key: "week_name",
    },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (status, row) => `$${row.admin}`,
    },
    {
      title: "Tutoring",
      dataIndex: "tutoring",
      key: "tutoring",
      render: (status, row) => `$${row.tutoring}`,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (status, row) => `$${row.total}`,
    },
    {
      title: "Paid",
      key: "Status",
      render: (status, row) =>
        row.id ? (
          <Tag
            color={
              row.paid === 0 ? "volcano" : row.paid === 1 ? "green" : "gold"
            }
          >
            {row.paid === 0
              ? "Not Paid"
              : row.paid === 1
              ? "Paid in Full"
              : `Partially Paid $${row.paid} / $${row.total}`}
          </Tag>
        ) : row.week_name === "Total" ? (
          `$${row.status}`
        ) : null,
    },
    {
      title: "Action",
      key: "paid",
      render: (text, record) =>
        record.id ? (
          <DropdownWithAddition
            options={options}
            value={record.paid}
            onChange={async (value) => {
              let data = new FormData();
              data.append("id", record.id);
              data.append("paid", value);
              let response = await dispatch(
                markTimesheetPaid({ token: token, body: data })
              );
              if (response.payload.success) {
                getData();
              }
            }}
          />
        ) : null,
    },
  ];
  const weekColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Admin",
      dataIndex: "admin_hours",
      key: "admin_hours",
      render: (status, row) => `$${row.admin}`,
    },
    {
      title: "Tutoring",
      dataIndex: "tutoring_hours",
      key: "tutoring_hours",
      render: (status, row) => `$${row.tutoring}`,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (status, row) => `$${row.total}`,
    },
    {
      title: "Paid",
      key: "Status",
      render: (status, row) =>
        row.id ? (
          <Tag
            color={
              row.paid === 0 ? "volcano" : row.paid === 1 ? "green" : "gold"
            }
          >
            {row.paid === 0
              ? "Not Paid"
              : row.paid === 1
              ? "Paid in Full"
              : `Partially Paid $${row.paid} / $${row.total}`}
          </Tag>
        ) : row.name === "Total" ? (
          `$${row.status}`
        ) : null,
    },
    {
      title: "Action",
      key: "paid",
      render: (text, record) =>
        record.id ? (
          <DropdownWithAddition
            options={options}
            value={record.paid}
            onChange={async (value) => {
              let data = new FormData();
              data.append("id", record.id);
              data.append("paid", value);
              let response = await dispatch(
                markTimesheetPaid({ token: token, body: data })
              );
              if (response.payload.success) {
                getData();
              }
            }}
          />
        ) : // <Checkbox
        //   indeterminate={record.paid > 1}
        //   checked={record.paid === 1 ? true : false}
        //   onChange={async (e) => {
        //     let data = new FormData();
        //     data.append("id", record.id);
        //     data.append("paid", e.target.checked ? 1 : 0);
        //     let response = await dispatch(
        //       markTimesheetPaid({ token: token, body: data })
        //     );
        //     if (response.payload.success) {
        //       getData();
        //     }
        //   }}
        // />
        null,
    },
  ];
  return (
    <div style={{ marginTop: 16, marginBottom: 16 }}>
      <Row style={{ alignItems: "center", marginBottom: 16 }}>
        <Col>
          <Select
            style={{ width: 300 }}
            showSearch
            value={selectedTutor}
            placeholder="Select a tutor"
            optionFilterProp="children"
            onChange={(value) => {
              setSelectedWeek();
              setSelectedTutor(value);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {data.map((x) => (
              <Option value={x.id}>{x.name}</Option>
            ))}
          </Select>
        </Col>
        <Col>-OR-</Col>
        <Col>
          <Select
            style={{ width: 300 }}
            placeholder="Select a week"
            value={selectedWeek}
            onChange={(value) => {
              setSelectedTutor();
              setSelectedWeek(value);
            }}
          >
            {weeks?.map((x) => (
              <Option value={x.id}>{x.name}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Table
        columns={selectedTutor ? columns : weekColumns}
        loading={loading}
        dataSource={selectedTutor ? timesheet : week}
        pagination={false}
        size="small"
      />
    </div>
  );
}
