import { DeleteOutlined, LockOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStudent } from '../Redux/Slices/HomeSlice'

export default function DeleteStudent({ record }) {
    const [show, setShow] = useState(false)
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const send = async () => {
        const response = await dispatch(deleteStudent({ token: auth.token, body: { id: record.id, password: form.getFieldValue('password') } }))
        if (response.payload) {
            setShow(false)
        }
    }
    return (
        <>
            <Tooltip title="Delete">
                <Button shape="circle" danger icon={<DeleteOutlined />} onClick={() => setShow(true)} />
            </Tooltip>
            <Modal title={`Are you sure you want to delete ${record.name} ?`} visible={show} onCancel={() => setShow(false)} onOk={send}>
                <Form form={form}>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter password' }]}
                    >
                        <Input.Password placeholder="Enter Password" prefix={<LockOutlined />} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
