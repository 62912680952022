/* eslint-disable react-hooks/exhaustive-deps */
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Input, Menu, Row, Select, Space, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import AddTutor from "../Components/AddTutor";
import DeleteTutor from "../Components/Tutor/DeleteTutor";
import EditTutor from "../Components/Tutor/EditTutor";
import Report from "../Components/Tutor/Report";
import TutorInvoice from "../Components/Tutor/TutorInvoice";
import WeeklyTimesheet from "../Components/Tutor/WeeklyTimesheet";
import { fetchTutors, getWeeks } from "../Redux/Slices/TutorSlice";
const { Option } = Select;
export default function Tutors() {
  const [tab, setTab] = useState("List");
  const dispatch = useDispatch();
  const [searchedText, setSearchedText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { token } = useSelector((state) => state.auth);
  const { data, loading } = useSelector((state) => state.tutors);
  const dashboardData = useSelector((state) => state.dashboard);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState();
  useEffect(() => {
    dispatch(
      fetchTutors({
        token: token,
      })
    );
    dispatch(
      getWeeks({
        token: token,
      })
    );
  }, []);
  useEffect(() => {
    if (dashboardData.terms) {
      setSelectedYear(dashboardData.terms.find((x) => x.current === 1).year);
      setSelectedTerm(dashboardData.terms.find((x) => x.current === 1).id);
    }
  }, [dashboardData]);
  const searchInput = useRef();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchedText("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) =>
        a.name.split(" ")[0].localeCompare(b.name.split(" ")[0]),
    },
    {
      title: "ABN",
      dataIndex: "abn",
      key: "abn",
    },
    {
      title: "Admin Rate",
      dataIndex: "admin_rate",
      key: "admin_rate",
    },
    {
      title: "Tutoring Rate",
      dataIndex: "tutoring_rate",
      key: "tutoring_rate",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <EditTutor record={record} />
          <DeleteTutor record={record} />
          <Tooltip title="View Invoice">
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => {
                setSelected(record);
                setVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div style={{ paddingTop: 16 }}>
      <Row style={{ margin: "8px 0px" }} gutter={8}>
        <Col>
          <Select
            style={{ width: 200 }}
            value={selectedYear}
            placeholder="Select Year"
            onChange={(value) => {
              setSelectedYear(value);
            }}
          >
            {dashboardData.termYears?.map((x) => (
              <Option value={x} key={`${x}`}>
                {x}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            style={{ width: 200 }}
            value={selectedTerm}
            placeholder="Select Term"
            onChange={(value) => {
              setSelectedTerm(value);
            }}
          >
            {dashboardData.terms?.map((x) => {
              if (x.year === selectedYear) {
                return (
                  <Option value={x.id} key={`${x.id}`}>
                    {x.term}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </Col>
      </Row>
      <Menu
        mode="horizontal"
        selectedKeys={[tab]}
        onClick={(e) => setTab(e.key)}
      >
        <Menu.Item key={"List"}>List</Menu.Item>
        <Menu.Item key={"Weekly Timesheet"}>Weekly Timesheet</Menu.Item>
        <Menu.Item key={"Report"}>Report</Menu.Item>
      </Menu>

      {tab === "List" ? (
        <Table
          columns={columns}
          loading={loading}
          dataSource={[...data].map((x, i) => ({ ...x, key: i }))}
          pagination={false}
          size="middle"
          scroll={{ scrollToFirstRowOnChange: true }}
        />
      ) : null}
      {tab === "Weekly Timesheet" ? (
        <WeeklyTimesheet term={selectedTerm} />
      ) : null}
      {tab === "Report" ? <Report term={selectedTerm} /> : null}
      <AddTutor />
      <Drawer
        title="Invoice"
        width={window.innerWidth > 900 ? 720 : "100%"}
        onClose={() => setVisible(false)}
        open={visible}
        destroyOnClose={true}
      >
        <TutorInvoice tutor={selected} term={selectedTerm}/>
      </Drawer>
    </div>
  );
}
