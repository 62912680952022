import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Input, Form, Button } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../Redux/Slices/AuthSlice'

export default function Login() {
    const [form] = Form.useForm()
    const auth = useSelector(state=>state.auth)
    const dispatch = useDispatch()
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column" }}>
            <div>
                <Form layout="vertical" form={form}>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please enter username' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: "Please enter password" }]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>
                </Form>
                <Button loading={auth.loading} type="primary" style={{ width: "100%" }}
                onClick={()=>{
                    dispatch(login(form.getFieldsValue()))
                }}>Login</Button>
            </div>
        </div>
    )
}
