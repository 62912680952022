import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const login = createAsyncThunk(
    'auth/login',
    async (data, thunkAPI) => {
        const response = await MakeRequest(null, thunkAPI).post('/login', data)
        return response.data
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authenticated:false,
        loading: false
    },
    reducers: {
        logout: state => {
            state.authenticated = false
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.loading = false
            state.authenticated = true
        },
        [login.pending]: (state) => {
            state.loading = true
        },
        [login.rejected]: (state) => {
            state.loading = false
        }
    }
})

export const { logout } = authSlice.actions
export default authSlice.reducer