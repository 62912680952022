import { EditOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Row, Col, Input, Select, DatePicker, Tooltip, Radio, notification,Checkbox } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateStudent } from '../Redux/Slices/HomeSlice';
const { Option } = Select;
export default function EditStudent({ record }) {
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm();
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        form.setFieldsValue(record)
        let dates = JSON.parse(record.dates)
        form.setFieldsValue({ "start_date": moment(dates[0], "DD-MM-YYYY") })
        if (record.payment_received > 1) {
            form.setFieldsValue({ "partial_amount": record.payment_received })
            form.setFieldsValue({ "payment_received": 2 })
        }
        // eslint-disable-next-line
    }, [record])
    const sendData = async () => {
        let isValid = await form.validateFields()
        if (isValid) {
            let data = form.getFieldsValue()
            for (let [key, value] of Object.entries(data)) {
                if (value === undefined) {
                    delete data[key]
                }
            }
            if (data.start_date.format('dddd') !== data.day) {
                const key = `open${Date.now()}`;
                notification.error({
                    message: 'Error Occured',
                    description: "Date and day dont match!",
                    key,
                });
                return
            }
            data.start_date = data.start_date.format('DD-MM-yyyy')
            data.id = record.id
            if (data.payment_received === 2) {
                if (data.partial_amount) {
                    data.payment_received = data.partial_amount
                } else {
                    notification.error({
                        message: "Error Occured",
                        description: "Please enter partially received amount",
                        key: `open${Date.now()}`
                    })
                    return
                }
            }
            dispatch(addUpdateStudent({
                token: auth.token,
                body: data
            }))
            setVisible(false)
            // let response = await fetch(`${url}/addUpdateStudent`, {
            //     method: "POST", body: JSON.stringify(data), headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': auth.token
            //     }
            // })
            // let responseJson = await response.json()
            // if (responseJson.success === true) {

            //     setVisible(false)
            //     form.resetFields()
            // }
        }
    }

    return (
        <>
            <Tooltip title="Edit">
                <Button shape="circle" icon={<EditOutlined />} onClick={() => setVisible(true)} />
            </Tooltip>
            <Drawer
                destroyOnClose={true}
                title="Add Student"
                width={window.innerWidth > 900 ? 720 : "100%"}
                onClose={() => setVisible(false)}
                visible={visible}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
                            Cancel
                      </Button>
                        <Button onClick={() => {
                            sendData()
                        }} type="primary">
                            Submit
                      </Button>
                    </div>
                }>
                <Form layout="vertical" form={form} initialValues={{
                    ignore_end_date:false
                }}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[{ required: true, message: 'Please enter user name' }]}
                            >
                                <Input placeholder="Please enter student name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone_number"
                                label="Phone Number"
                            >
                                <Input placeholder="Please enter phone number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                            >
                                <Input placeholder="Please enter email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email_secondary"
                                label="Secondary Email"
                            >
                                <Input placeholder="Please enter secondary email" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="grade"
                                label="Grade"
                                rules={[{ required: true, message: 'Please enter grade' }]}
                            >
                                <Input placeholder="Please enter grade" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="subject"
                                label="Subject"
                                rules={[{ required: true, message: 'Please enter subject' }]}
                            >
                                <Input placeholder="Please enter subject" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="day"
                                label="Day"
                                rules={[{ required: true, message: 'Please choose the day' }]}
                            >
                                <Select placeholder="Please choose the day">
                                    <Option value="Sunday">Sunday</Option>
                                    <Option value="Monday">Monday</Option>
                                    <Option value="Tuesday">Tuesday</Option>
                                    <Option value="Wednesday">Wednesday</Option>
                                    <Option value="Thursday">Thursday</Option>
                                    <Option value="Friday">Friday</Option>
                                    <Option value="Saturday">Saturday</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="time"
                                label="Time"
                                rules={[{ required: true, message: "Please enter time" }]}
                            >
                                <Input placeholder="Please enter time" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="session_fee"
                                label="Fee/Session"
                                rules={[{ required: true, message: 'Please enter fee/session' }]}
                            >
                                <Input placeholder="Please enter fee/session" type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="session_discount"
                                label="Discount/Session"
                                rules={[{ required: true, message: "Please enter discount/session" }]}
                            >
                                <Input placeholder="Please enter discount/session" type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="start_date"
                                label="Start Date"
                                rules={[{ required: true, message: 'Please choose the start date' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    getPopupContainer={trigger => trigger.parentElement}
                                    format={"DD-MM-YYYY"}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="number_of_sessions"
                                label="Number of sessions">
                                <Input placeholder="Please enter number of sessions" type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                            name="ignore_end_date"
                            label="  "
                            valuePropName="checked">
                                <Checkbox>Ignore End Date</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="invoice_sent"
                                label="Invoice Sent ?"
                                rules={[{ required: true, message: 'Please select if the invoice is sent' }]}
                            >
                                <Radio.Group defaultValue={0}>
                                    <Radio.Button value={0}>No</Radio.Button>
                                    <Radio.Button value={1}>Yes</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="payment_received"
                                label="Payment received ?"
                                rules={[{ required: true, message: 'Please select if the payment is received' }]}
                            >
                                <Radio.Group>
                                    <Radio.Button value={0}>No</Radio.Button>
                                    <Radio.Button value={1}>Yes</Radio.Button>
                                    <Radio.Button value={2}>Partial</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="confirmed"
                                label="Confirmed ?"
                                rules={[{ required: true, message: 'Please select if the confirmed' }]}
                            >
                                <Radio.Group>
                                    <Radio.Button value={0}>No</Radio.Button>
                                    <Radio.Button value={1}>Yes</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="partial_amount"
                                label="Partially received amount">
                                <Input placeholder="Enter Partially received amount" type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="payment_method"
                                label="Payment Method">
                                <Radio.Group>
                                    <Radio.Button value={null}>None</Radio.Button>
                                    <Radio.Button value={"Cash"}>Cash</Radio.Button>
                                    <Radio.Button value={"Bank"}>Bank</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}
