/* eslint-disable react-hooks/exhaustive-deps */
import { Select, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import {  useSelector } from "react-redux";
import { url } from "../../Utils/Config";
import EditableTable from "../EditableTable";
const { Option } = Select;
export default function WeeklyTimesheet({term}) {
  const [selectedTutor, setSelectedTutor] = useState();
  const [selectedWeek, setSelectedWeek] = useState();
  const { data, weeks } = useSelector((state) => state.tutors);
  const { token } = useSelector((state) => state.auth);
  const [timesheet, setTimesheet] = useState([]);
  const [week, setWeek] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (selectedTutor) {
      getData();
    }
  }, [selectedTutor,term]);
  useEffect(() => {
    if (selectedWeek) {
      getWeeklyData();
    }
  }, [selectedWeek,term]);
  const getData = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("id", selectedTutor);
    if(term && term !== null){
      data.append("term_id",term)
    }
    const response = await fetch(`${url}/fetchTimesheet`, {
      credentials:"include",
      method: "POST",
      body: data,
    });
    const responseJson = await response.json();
    setTimesheet(responseJson.details);
    setLoading(false);
  };
  const getWeeklyData = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("id", selectedWeek);
    if(term && term !== null){
      data.append("term_id",term)
    }
    const response = await fetch(`${url}/fetchWeek`, {
      method: "POST",
      body: data,
      credentials:"include"
    });
    const responseJson = await response.json();
    setWeek(responseJson.details);
    setLoading(false);
  };
  const updatetimesheet = async (row) => {
    if (row.id) {
      let record = timesheet.find((x) => x.id === row.id);
      if (
        row.admin_hours === record.admin_hours &&
        row.tutoring_hours === record.tutoring_hours
      ) {
        return;
      }
    }
    setLoading(true);
    let data = new FormData();
    if (row.id) {
      data.append("id", row.id);
    }
    data.append("tutor_id", selectedTutor);
    data.append("week_id", row.week_id);
    data.append("term_id",term)
    if (row.admin_hours) {
      data.append("admin_hours", row.admin_hours);
    }
    if (row.tutoring_hours) {
      data.append("tutoring_hours", row.tutoring_hours);
    }
    const response = await fetch(`${url}/addUpdateTimesheet`, {
      method: "POST",
      body: data,
      credentials:"include"
    });
    const responseJson = await response.json();
    if (responseJson.success) {
      await getData();
    }
  };
  const updateWeeklyTimesheet = async (row) => {
    if (row.id) {
      let record = week.find((x) => x.id === row.id);
      if (
        row.admin_hours === record.admin_hours &&
        row.tutoring_hours === record.tutoring_hours
      ) {
        return;
      }
    }
    setLoading(true);
    let data = new FormData();
    if (row.id) {
      data.append("id", row.id);
    }
    data.append("tutor_id", row.tutor_id);
    data.append("week_id", selectedWeek);
    if (row.admin_hours) {
      data.append("admin_hours", row.admin_hours);
    }
    if (row.tutoring_hours) {
      data.append("tutoring_hours", row.tutoring_hours);
    }
    data.append("term_id",term)
    const response = await fetch(`${url}/addUpdateTimesheet`, {
      method: "POST",
      body: data,
      credentials:"include"
    });
    const responseJson = await response.json();
    if (responseJson.success) {
      await getWeeklyData();
    }
  };
  return (
    <div style={{ marginTop: 16, marginBottom: 16 }}>
      <Row style={{ alignItems: "center" }}>
        <Col>
          <Select
            style={{ width: 300 }}
            showSearch
            value={selectedTutor}
            placeholder="Select a tutor"
            optionFilterProp="children"
            onChange={(value) => {
              setSelectedWeek();
              setSelectedTutor(value);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {data.map((x) => (
              <Option value={x.id}>{x.name}</Option>
            ))}
          </Select>
        </Col>
        <Col>-OR-</Col>
        <Col>
          <Select
            style={{ width: 300 }}
            placeholder="Select a week"
            value={selectedWeek}
            onChange={(value) => {
              setSelectedTutor();
              setSelectedWeek(value);
            }}
          >
            {weeks?.map((x) => (
              <Option value={x.id}>{x.name}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      {selectedTutor ? (
        <EditableTable
          dataSource={timesheet}
          loading={loading}
          handleSave={updatetimesheet}
          columns={[
            {
              title: "Week",
              dataIndex: "week_name",
              key: "week_name",
            },
            {
              title: "Admin Hours",
              dataIndex: "admin_hours",
              width: "30%",
              editable: true,
              key: "admin_hours",
            },
            {
              title: "Tutoring Hours",
              dataIndex: "tutoring_hours",
              width: "30%",
              editable: true,
              key: "tutoring_hours",
            },
          ]}
        />
      ) : null}
      {selectedWeek ? (
        <EditableTable
          dataSource={week}
          loading={loading}
          handleSave={updateWeeklyTimesheet}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Admin Hours",
              dataIndex: "admin_hours",
              width: "30%",
              editable: true,
              key: "admin_hours1",
            },
            {
              title: "Tutoring Hours",
              dataIndex: "tutoring_hours",
              width: "30%",
              editable: true,
              key: "tutoring_hours1",
            },
          ]}
        />
      ) : null}
    </div>
  );
}
