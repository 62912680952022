import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MakeRequest from "../../Utils/MakeRequest";

export const fetchCrms = createAsyncThunk(
  "crms/fetch",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).get(
      "/fetchCrms"
    );
    return response.data;
  }
);
export const addCrm = createAsyncThunk("crms/add", async (data, thunkAPI) => {
  const response = await MakeRequest( null, thunkAPI).post(
    "/addCrm",
    data.body
  );
  return response.data;
});
export const editCrm = createAsyncThunk("crms/edit", async (data, thunkAPI) => {
  const response = await MakeRequest(null, thunkAPI).post(
    "/editCrm",
    data.body
  );
  return response.data;
});
export const deleteCrm = createAsyncThunk(
  "crms/delete",
  async (data, thunkAPI) => {
    const response = await MakeRequest(null, thunkAPI).post(
      "/deleteCrm",
      data.body
    );
    return response.data;
  }
);
export const addCrmComment = createAsyncThunk(
  "crms/addCrmComment",
  async (data, thunkAPI) => {
    const response = await MakeRequest( null, thunkAPI).post(
      "/addCrmComment",
      data.body
    );
    return response.data;
  }
);
const crmSlice = createSlice({
  name: "crms",
  initialState: {
    data: [],
    loading: false,
  },
  extraReducers: {
    [fetchCrms.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.details;
    },
    [fetchCrms.pending]: (state) => {
      state.loading = true;
    },
    [fetchCrms.rejected]: (state) => {
      state.loading = false;
    },
    [addCrm.fulfilled]: (state, { payload }) => {
      let data = state.data;
      data.push(payload.details);
      state.data = data;
    },
    [editCrm.fulfilled]: (state, { payload }) => {
      let data = payload.details;
      let newData = [];
      state.data.forEach((x) => {
        if (x.id === data.id) {
          newData.push(data);
        } else {
          newData.push(x);
        }
      });
      state.data = newData;
    },
    [deleteCrm.fulfilled]: (state, { payload }) => {
      let newData = state.data.filter((x) => x.id !== payload.details);
      state.data = newData;
    },
    [addCrmComment.fulfilled]: (state, { payload }) => {
      let data = payload.details;
      let newData = [];
      state.data.forEach((x) => {
        if (x.id === data.id) {
          newData.push(data);
        } else {
          newData.push(x);
        }
      });
      state.data = newData;
    },
  },
});

export default crmSlice.reducer;
